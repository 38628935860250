import React  from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { AUTHENTICATED_ENTRY } from 'configs/AppConfig'
import { useAuth } from 'contexts/AuthContext';

const PublicRoute = () => {
	const { signed } = useAuth();

	return signed ? <Navigate to={AUTHENTICATED_ENTRY} /> : <Outlet/>
}

export default PublicRoute